import React, { useState } from 'react';
import { Form, Button, Alert, Container } from 'react-bootstrap';
import SectionFooter from './Homepage/SectionFooter';
import SectionHero from './Homepage/SectionHero';
import axios from 'axios';

const AccountDeletionPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [reason, setReason] = useState<string>('');
    const [confirmation, setConfirmation] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (confirmation !== 'DELETE') {
            setError('Please type "DELETE" to confirm.');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/delete-account-request`, { email, reason, confirmation }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setLoading(false);
            // if (!response.data) {
            //     throw new Error('Failed to delete account.');
            // }

            setSuccess(response.data.message);
            setEmail('');
            setReason('');
            setConfirmation('');
            setError('');
        } catch (error: any) {
            setLoading(false);
            console.log(error);
            if (error.response && error.response.data) {
                setError(error.response.data.message);
            }
            else {
                setError("Sorry unable to send deletion request.  Please check your details submitted");
            }
            // setError(JSON.stringify(error));
        }
    };

    return (
        <Container fluid className="p-0 d-flex flex-column min-vh-100">
            <SectionHero />
            <div className="flex-grow-1 d-flex align-items-center justify-content-center py-5">
                <Container className="mt-5 bg-white rounded p-4" style={{ maxWidth: '500px' }}>
                    <div className="text-center mb-4">
                        <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px' }}>Delete Your Account</span>
                    </div>
                    <p className="text-center mb-4">We’re sorry to see you go. Please follow the steps below to delete your account.</p>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="reason">
                            <Form.Label>Reason for Leaving (optional)</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter reason"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                rows={3}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="confirmation">
                            <Form.Label>Type "DELETE" to confirm</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder='Type "DELETE"'
                                value={confirmation}
                                onChange={(e) => setConfirmation(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="danger" type="submit" className="w-100" disabled={loading}>
                            {loading?"Please wait...":"Delete Account"}
                        </Button>
                    </Form>
                    <p className="text-center mt-4"><a href="/">Return to Home</a></p>

                </Container>
            </div>
            <SectionFooter />
        </Container>
    );
};

export default AccountDeletionPage;
