import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

const enableActions = false;

interface NutritionValue {
  name: string;
  value: string;
}
const NutritionValues: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [nutritionValues, setNutritionValues] = useState<NutritionValue[]>([]);
  
  const handleAddNutritionValue = () => {
    setShowModal(true);
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleSave = (text: string) => {
    const lines = text.split('\n');
    const newNutritionValues: NutritionValue[] = lines.map(line => {
      const [name, ...valueParts] = line.split(/\s+/);
      const value = valueParts.join(' ').trim();
      return { name: name.trim(), value };
    });
    setNutritionValues(newNutritionValues);
    setShowModal(false);
  };

  const handleRemoveNutritionValue = (index: number) => {
    const updatedNutritionValues = [...nutritionValues];
    updatedNutritionValues.splice(index, 1);
    setNutritionValues(updatedNutritionValues);
  };

  return (
    <div>
      {enableActions && <Button onClick={handleAddNutritionValue} variant="dark" className='my-2 btn-sm'>Add Nutrition Value</Button>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
            {enableActions && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {nutritionValues.length === 0 && <tr>
            <td colSpan={3}>No nutrition values added</td>
          </tr>}
          {nutritionValues.map((nutritionValue, index) => (
            <tr key={index}>
              <td>{nutritionValue.name}</td>
              <td>{nutritionValue.value}</td>
              {enableActions && <td>
                <Button variant="danger" size="sm" onClick={() => handleRemoveNutritionValue(index)}>Remove</Button>
              </td>}
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Nutrition Value</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea className="form-control" rows={5} id="nutritionValueTextArea"></textarea>
          <p>Example format: Name Value</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={() => handleSave((document.getElementById('nutritionValueTextArea') as HTMLTextAreaElement).value)}>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NutritionValues;
