import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

interface Ingredient {
  name: string;
  quantity: string;
  unit_type: string;
  is_primary: boolean;
}
const enableActions = false;
const IngredientList: React.FC<{ dataList: Ingredient[] }> = ({ dataList }) => {
  const [showModal, setShowModal] = useState(false);
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  useEffect(() => {
    setIngredients(dataList);
  }, [dataList]);
  const handleAddIngredient = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = (text: string) => {
    const lines = text.split('\n');
    const newIngredients: Ingredient[] = lines.map(line => {
      const [rawNameQty, unit_type, is_primary] = line.split(/\s+(\w+)$/);
      let [nameQty, quantity] = rawNameQty.split(/(?<=\d)\s+(?=\D|$)/);
      if (!quantity) {
        // Try to parse old format
        const oldFormatMatch = rawNameQty.match(/^(.*):\s*(\d+)$/);
        if (oldFormatMatch) {
          nameQty = oldFormatMatch[1];
          quantity = oldFormatMatch[2];
        }
      }
      const [name, qtyValue] = nameQty.split(/\s+(?=\d|$)/);
      const parsedQty = qtyValue ? qtyValue : 'N/A';
      const parsedUnit = unit_type ? unit_type : 'N/A';
      const parsedMandatory = is_primary ? is_primary.toLowerCase() === 'true' : true;
      return { name: name.trim(), quantity: parsedQty.trim(), unit_type: parsedUnit.trim(), is_primary: parsedMandatory };
    });
    setIngredients(newIngredients);
    setShowModal(false);
  };

  const handleToggleMandatory = (index: number) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients[index].is_primary = !updatedIngredients[index].is_primary;
    setIngredients(updatedIngredients);
  };

  const handleRemoveIngredient = (index: number) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);
  };

  return (
    <div>
      {enableActions && <Button onClick={handleAddIngredient} variant="dark" className='my-2 btn-sm'>Add Ingredient</Button>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
            <th>Unit</th>
            <th>Mandatory</th>
            {enableActions && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {ingredients && ingredients.length === 0 && <tr>
            <td colSpan={5}>No ingredients added</td>
          </tr>}
          {ingredients && ingredients.map((ingredient, index) => (
            <tr key={index}>
              <td>{ingredient.name}</td>
              <td>{ingredient.quantity}</td>
              <td>{ingredient.unit_type}</td>
              <td>
                <input type="checkbox" checked={ingredient.is_primary} onChange={() => handleToggleMandatory(index)} disabled={!enableActions} />
              </td>
              {enableActions && <td>
                <Button variant="danger" size="sm" onClick={() => handleRemoveIngredient(index)}>Remove</Button>
              </td>}
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Ingredients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea className="form-control" rows={8} id="ingredientTextArea"></textarea>
          <p className="my-2 text-start w-100" style={{ fontSize: 13, background: "#000000", color: "#CCCCCC", padding: 10 }}>
            Example Format: &nbsp;
            <br />
            <i>Potato</i>&nbsp;
            <i>2</i>&nbsp;
            <i>Pcs</i>&nbsp;
            <br />
            <i>Water</i>&nbsp;
            <i>1/5</i>&nbsp;
            <i>Cup</i>&nbsp;
            <br />
            <i>Salt</i>&nbsp;
            <i>1/2</i>&nbsp;
            <i>Teaspoon</i>&nbsp;
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={() => handleSave((document.getElementById('ingredientTextArea') as HTMLTextAreaElement).value)}>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IngredientList;
