import React from 'react';
import { Container } from 'react-bootstrap';
import SectionHero from './Homepage/SectionHero';
import SectionFooter from './Homepage/SectionFooter';

const PrivacyPolicy: React.FC = () => {
    return (
        <Container fluid className="p-0">
            <SectionHero />
            <Container className="py-5">
                <h1 className="text-center mb-4">Privacy Policy</h1>
                <p>
                    At NutreeLover.com, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our mobile application. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the application.
                </p>
                <h3>Collection of your Information</h3>
                <p>
                    We may collect information about you in a variety of ways. The information we may collect includes:
                    <ul>
                        <li>Personal Data</li>
                        <li>Usage Data</li>
                        <li>Location Data</li>
                        <li>Device Information</li>
                    </ul>
                </p>
                <h3>Use of your Information</h3>
                <p>
                    We may use the information we collect about you to:
                    <ul>
                        <li>Provide and maintain our services</li>
                        <li>Improve our services</li>
                        <li>Respond to your inquiries</li>
                        <li>Monitor usage of our services</li>
                        <li>Send you promotional communications</li>
                    </ul>
                </p>
                <h3>Disclosure of your Information</h3>
                <p>
                    We may share your information with third parties only in the ways that are described in this Privacy Policy. We may disclose your personal information:
                    <ul>
                        <li>As required by law</li>
                        <li>With service providers</li>
                        <li>With affiliates</li>
                        <li>With business partners</li>
                    </ul>
                </p>
                <h3>Contact Us</h3>
                <p>
                    If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:support@nutreelover.com">support@nutreelover.com</a>.
                </p>
            </Container>
            <SectionFooter />
        </Container>
    );
}

export default PrivacyPolicy;
