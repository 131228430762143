import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showMessage = (msg: string) => {
  toast.success(msg, {
    position: 'top-center',
    autoClose: 3000,
  });
};

const showError = (msg: string) => {
  toast.error(msg, {
    position: 'top-center',
    autoClose: 3000,
  });
};

const ToastComponent: React.FC = () => {
  return (
    <ToastContainer />
  );
};

export { showMessage, showError, ToastComponent };
