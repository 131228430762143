import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import SectionHero from './Homepage/SectionHero';
import SectionFooter from './Homepage/SectionFooter';

const AboutUs: React.FC = () => {
    return (
        <Container fluid className="p-0">
            <SectionHero />
            <Container className="py-5">
                <h1 className="text-center mb-4">About Us</h1>
                <Row className="align-items-center">
                    <Col md={6} className="mb-4 mb-md-0">
                        <Image 
                            src={require("../../assets/images/about-us.png")} 
                            alt="About Us Image" 
                            fluid 
                            rounded 
                            width={500}
                        />
                    </Col>
                    <Col md={6}>
                        <p>
                            <b>Satiate LLC</b> is a premier digital marketing company dedicated to helping businesses thrive in the digital landscape. Our mission is to provide comprehensive and innovative marketing solutions that drive growth and engagement.
                        </p>
                        <p>
                            With a team of seasoned experts in SEO, social media, content marketing, and online advertising, we craft personalized strategies to meet the unique needs of each client. At <b>Satiate LLC</b>, we are passionate about delivering measurable results and ensuring our clients' success in the ever-evolving digital world.
                        </p>
                        <p>
                            Our commitment to excellence and innovation makes us a trusted partner for businesses looking to enhance their online presence and achieve their marketing goals. Join us on our journey to success in the digital realm.
                        </p>
                    </Col>
                </Row>
            </Container>
            <SectionFooter />
        </Container>
    );
}

export default AboutUs;
