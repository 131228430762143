import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { showError, showMessage } from '../../utils/error-handler';
import { API_RESET_PASSWORD } from '../../backend/apis';
import api from '../../backend/axios-config';

const ResetPassword: React.FC = () => {
    const [formObject, setFormObject] = useState({
        token: '',
        password: '',
        confirmPassword: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    useEffect(() => {
        const token = query.get('token');
        if (token) {
            setFormObject(prevState => ({ ...prevState, token }));
        } else {
            setError('Token is missing. Please use the link provided in the email.');
        }
    }, [query]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (formObject.password.trim() === '' || formObject.confirmPassword.trim() === '') {
            setError('Password fields are required');
            return;
        } else if (formObject.password !== formObject.confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            setLoading(true);
            const response = await api.post(API_RESET_PASSWORD, formObject);
            showMessage(response.data.message);
            if (response.data.status === 200) {
                setMessage('Password has been reset successfully.');
                setFormObject({ token: '', password: '', confirmPassword: '' });
            } else {
                setError(response.data.message);
            }
        } catch (error: any) {
            showError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="vh-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: "#003123" }}>
            <Container className="m-4 mt-5 bg-white rounded p-4" style={{ maxWidth: '400px' }}>
                <div className="text-center mb-4">
                    <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px', position: 'relative', top: '-10px' }}>Reset Password</span>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={formObject.password}
                            onChange={(e) => setFormObject({ ...formObject, password: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm new password"
                            value={formObject.confirmPassword}
                            onChange={(e) => setFormObject({ ...formObject, confirmPassword: e.target.value })}
                        />
                    </Form.Group>

                    {error && <div className="text-danger mb-3">{error}</div>}
                    {message && <div className="text-success mb-3">{message}</div>}

                    <Button variant="success" type="submit" className="w-100" disabled={loading}>
                        {loading ? 'Resetting...' : 'Reset Password'}
                    </Button>

                    <div className="mt-3 text-center">
                        <Link to="/login">Return to Login</Link>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default ResetPassword;
