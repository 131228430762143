// Define the key for storing the token in localStorage

// Define the key for storing the token in AsyncStorage
const TOKEN_KEY_ACCESS = 'access_token';
const TOKEN_KEY_REFRESH = 'refresh_token';


// Function to store the token in AsyncStorage
export const storeToken = async (tokens: any) => {
  try {
    await localStorage.setItem(TOKEN_KEY_ACCESS, tokens.token);
    await localStorage.setItem(TOKEN_KEY_REFRESH, tokens.refreshToken);
  } catch (error) {
    console.error('Error storing token:', error);
    // Handle error (e.g., display error message)
  }
};

// // Function to store the token in localStorage
// export const storeToken = (token: string): void => {
//   try {
//     localStorage.setItem(TOKEN_KEY_ACCESS, token);
//   } catch (error) {
//     console.error('Error storing token:', error);
//     // Handle error (e.g., display error message)
//   }
// };


export const setAccessToken = (accessToken: any) => {
  try {
    localStorage.setItem(TOKEN_KEY_ACCESS, accessToken);
  } catch (error) {
    console.error('Error storing token:', error);
    // Handle error (e.g., display error message)
  }
};


// Function to retrieve the token from AsyncStorage
export const getAccessToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY_ACCESS);
    return token;
  } catch (error) {
    console.error('Error retrieving token:', error);
    // Handle error (e.g., display error message)
    return null;
  }
};


// Function to retrieve the token from AsyncStorage
export const getRefreshToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY_REFRESH);
    return token;
  } catch (error) {
    console.error('Error retrieving refresh token:', error);
    // Handle error (e.g., display error message)
    return null;
  }
};


// Function to remove the token from AsyncStorage
export const removeTokens = () => {
  try {
    localStorage.removeItem(TOKEN_KEY_ACCESS);
    localStorage.removeItem(TOKEN_KEY_REFRESH);
  } catch (error) {
    console.error('Error removing tokens:', error);
    // Handle error (e.g., display error message)
  }
};

// // Function to retrieve the token from localStorage
// export const getToken = (): string | null => {
//   try {
//     const token = localStorage.getItem(TOKEN_KEY_ACCESS);
//     return token;
//   } catch (error) {
//     console.error('Error retrieving token:', error);
//     // Handle error (e.g., display error message)
//     return null;
//   }
// };

// // Function to remove the token from localStorage
// export const removeToken = (): void => {
//   try {
//     localStorage.removeItem(TOKEN_KEY_ACCESS);
//   } catch (error) {
//     console.error('Error removing token:', error);
//     // Handle error (e.g., display error message)
//   }
// };
