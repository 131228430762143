import React from 'react';
import './Homepage.css';
import { Container, Row, Col, Button, Card, ListGroup } from 'react-bootstrap';
import SectionHero from './SectionHero';
import SectionFooter from './SectionFooter';

const Homepage: React.FC = () => {
  return (
    <Container fluid className="p-0">
      <SectionHero />

      <Container id="intro" className="py-5">
        <Row className="justify-content-center text-center">
          <Col md={8}>
            <p>Nutree Lover is a mobile app that will <b>soon be available</b> on <b>Google</b> and <b>iOS</b> Marketplaces. It features recipe identification based on only available ingredients.</p>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mt-4"
        // style={{ border: "20px outset #5c4201" }}>
        >
          <Col xs={12} md={3}>
            <img src={require('../../../assets/images/icon-phone-ios.png')} alt="iPhone" className="img-fluid" style={{ height: 325 }} />
            <br />
            <a href="https://apps.apple.com/us/app/nutree-lover/id6547831801" target="_blank">
              <img src={require('../../../assets/images/logo-appstore.png')} alt="App Store" className="img-fluid" style={{ width: 150 }} />
            </a>
          </Col>
          <Col xs={12} md={3}>

            {/* <img src={require('../../../assets/images/icon-phone-android.png')} alt="Android" className="img-fluid" style={{ height: 325 }} /> */}
            <img src={require('../../../assets/images/icon-phone-android-grey.png')} alt="Android" className="img-fluid" style={{ height: 325 }} />
            <br />
            <a href="https://play.google.com/store/apps/details?id=com.nutreelover" target="_blank">
              <img src={require('../../../assets/images/logo-playstore.png')} alt="App Store" className="img-fluid" style={{ width: 150 }} />
            </a>
          </Col>
          {/* <Col xs={12} md={12}>
          <a href="https://nutreelover.com/app-beta-build/nutreelover-v0.0.1-beta.apk">Download Beta Version</a>
          </Col> */}
        </Row>
      </Container>

      <div className='w-100 py-4' style={{ background: "rgba(0,0,0,0.05)", fontSize: 16, textAlign: "center" }}>
        <Container>
          <Row className="justify-content-center">
            {/* <p style={{ fontSize: 24 }}>Craft personalized meal plans for health and weight management, using fresh ingredients and efficient cooking methods to save time and energy.</p> */}
            <p className="m-0" style={{ fontSize: 24 }}>Customize meal plans for health and weight management with fresh, home-cooked dishes tailored to your taste preferences, saving time and energy while enjoying nutritious meals.</p>
            {/* <Col md={6}> */}
            {/* <div className="value-card primary-value">
                <h3 className="text-white text-center">Primary Value</h3>
                <p className="text-white text-center">Time and energy savings in meal planning.</p>
              </div> */}
            {/* <p className="text-center">Time and energy savings in meal planning</p> */}
            {/* </Col> */}
            {/* <Col md={6}> */}
            {/* <div className="value-card secondary-value">
                <h3 className="text-white text-center">Secondary Value</h3>
                <p className="text-white text-center">Nutritious food with little or no planning. Build new cooking skills and palette. Health and weight management. Fresh and home-cooked food based on customer’s liking and taste.</p>
              </div> */}
            {/* <p className="text-center">Nutritious food with little or no planning.</p> */}
            {/* </Col> */}
          </Row>
        </Container>
      </div>

      <Container fluid className="py-5" style={{}}>
        <Container>
          <h2 className="text-dark text-center mb-4">Who can use this app?</h2>
          <Row>
            <Col xs={12} md={3} className="mb-4">
              <Card style={{ borderColor: "#005500" }}>
                <Card.Img variant="top" src={require('../../../assets/images/target-audience/single-parents.png')} />
                <Card.Body>
                  <Card.Title>Single Parents</Card.Title>
                  <Card.Text>Helping single parents create quick, nutritious meals for their families.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3} className="mb-4">
              <Card style={{ borderColor: "#005500" }}>
                {/* <Card.Img variant="top" src="https://static.wixstatic.com/media/11062b_1bcf3e3ee4ef417c819f8632f5a6e896~mv2_d_5069_3445_s_4_2.jpg/v1/fill/w_640,h_434,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/11062b_1bcf3e3ee4ef417c819f8632f5a6e896~mv2_d_5069_3445_s_4_2.jpg" /> */}
                <Card.Img variant="top" src={require('../../../assets/images/target-audience/working-families.webp')} />
                <Card.Body>
                  <Card.Title>Working Families</Card.Title>
                  <Card.Text>Supporting busy families in planning and preparing healthy meals together.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3} className="mb-4">
              <Card style={{ borderColor: "#005500" }}>
                <Card.Img variant="top" src={require('../../../assets/images/target-audience/students-cooking.jpg')} />
                <Card.Body>
                  <Card.Title>Students</Card.Title>
                  <Card.Text>Guiding students in making simple, affordable, and nutritious meals.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3} className="mb-4">
              <Card style={{ borderColor: "#005500" }}>
                <Card.Img variant="top" src={require('../../../assets/images/target-audience/millennials.jpg')} />
                <Card.Body>
                  <Card.Title>Millennials</Card.Title>
                  <Card.Text>Empowering millennials to cook at home with easy-to-follow recipes.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>

      {
        false && <Container className="py-5">
          <h2 className="text-primary text-center">Winning Aspiration, Mission & Strategy Statement</h2>
          <Row>
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Goal</Card.Title>
                  <Card.Text>Bring to the market a personalized and easy-to-use meal planning app called Nutree Lover to address a familiar quandary: what to eat?</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Mission</Card.Title>
                  <Card.Text>To assist people in creating meals prepared at home by providing step-by-step recipes using available ingredients. Additionally, we aim to help families create fun memories and enjoy quality time cooking together to build a bonding experience.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Strategy Statement</Card.Title>
                  <Card.Text>To get the first 1000 paid subscribers by the end of 2024 by offering personalized recipes to all levels of cooks by promoting the use of available ingredients in the average American or Indian families.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      }

      {
        false && <Container fluid className="bg-light py-5">
          <Container>
            <h2 className="text-primary text-center">Value Proposition</h2>
            <Row>
              <Col md={6}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Which problem are we trying to solve?</Card.Title>
                    <Card.Text>What to cook with my choice of ingredients and diet preferences? How can I use available ingredients to create a nutritious meal? Recipe lookups with a difference.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Bundles</Card.Title>
                    <Card.Text>Technology & resource tool to create a profile and access desired recipes based on ingredients, nutrition requirements, and cooking lifestyle. Recommendations on boosting the ingredients in the pantry, meals, and grocery planning based on personal profile and preferences.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Target customers and objectives</Card.Title>
                    <Card.Text>Home cooks looking for inspiration to counter palate boredom and food waste:</Card.Text>
                    <ListGroup>
                      <ListGroup.Item>Experienced home cook: Avoiding palate boredom - use simple ingredients, dig into the pantry & from the kitchen garden.</ListGroup.Item>
                      <ListGroup.Item>Aspirational cook: Basics - ingredients to stock up on for quick healthy meals, how to pair the common ingredients.</ListGroup.Item>
                      <ListGroup.Item>Health-conscious cook - prioritize health, nutrition, and particular diet.</ListGroup.Item>
                      <ListGroup.Item>Food Waste Conscious: Minimalist, garden to table, less is more.</ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      }


      {
        false && <Container fluid className="bg-light py-5">
          <Container>
            <h2 className="text-primary text-center">Target Demographics</h2>
            <Row className="justify-content-center">
              <Col md={6}>
                <ListGroup>
                  <ListGroup.Item>Working families</ListGroup.Item>
                  <ListGroup.Item>Single parents</ListGroup.Item>
                  <ListGroup.Item>Health-conscious individuals</ListGroup.Item>
                  <ListGroup.Item>Students, Millennials</ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </Container>
      }

      <SectionFooter />
    </Container >
  );
}

export default Homepage;
