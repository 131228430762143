import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, Table, Breadcrumb, Pagination, Form, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { showError } from '../../utils/error-handler';
import { API_GET_USERS } from '../../backend/apis';
import api from '../../backend/axios-config';
import { formatDate } from '../../utils/date-utils';
import debounce from 'lodash/debounce';

const Users: React.FC = () => {
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [usersList, setUsersList] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStats();
    }, []);

    useEffect(() => {
        const delayFetch = debounce(fetchUsersList, 300);
        delayFetch();
        return () => delayFetch.cancel();
    }, [searchTerm, currentPage, pageSize]);

    const fetchStats = async () => {
        try {
            const sampleStats = {
                totalUsers: 10,
                totalRecipes: 500,
                totalIngredients: 2000,
                newUsersToday: 50,
            };
            setTotalUsers(sampleStats.totalUsers);
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    const fetchUsersList = useCallback(async () => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            const response = await api.get(API_GET_USERS, {
                params: {
                    keyword: searchTerm,
                    page: currentPage,
                    pageSize: pageSize
                }
            });
            setLoading(false);
            const users = response.data["data"];
            setUsersList(users);
        } catch (error: any) {
            console.error('Error fetching users list:', error);
            setLoading(false);
            showError(error.message);
        }
    }, [searchTerm, currentPage, pageSize, loading]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(parseInt(e.target.value));
    };

    return (
        <Container fluid className="mt-3">
            <Row className="mt-3">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Users</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header as="h5">Users List</Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by name"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </Form.Group>
                            <Table striped bordered hover responsive style={{ textAlign: "start" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Email Verification</th>
                                        <th>Phone</th>
                                        <th>On Boarding</th>
                                        <th>Registered On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usersList.length === 0 ? (
                                        <tr><td colSpan={8}>No Record Found</td></tr>
                                    ) : (
                                        usersList.map((user, index) => (
                                            <tr key={"user-" + user.id}>
                                                <td>{(index + 1) + ((currentPage - 1) * pageSize)}</td>
                                                <td>{user.first_name + " " + user.last_name}</td>
                                                <td><a href={"mailto:" + user.email} className='text-decoration-none text-dark'>{user.email}</a></td>
                                                <td>{user.is_email_verified ? <span className='mx-2 badge bg-success'>verified</span> : <span className='mx-2 badge bg-warning text-dark'>pending</span>}</td>
                                                <td><a href={"tel:" + user.phone} className='text-decoration-none text-dark'>{user.phone}</a></td>
                                                <td><Badge>{user.onboarding_status}</Badge></td>
                                                <td>{formatDate(user.created_date_time)}</td>
                                                <td>
                                                    <Link className="btn btn-success" to={"/users/" + user.id}>View Details</Link>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                            <Row>
                                <Col className='d-flex' md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Users Per Page:</Form.Label>
                                        <Form.Select onChange={handlePageSizeChange} value={pageSize}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                    <Pagination>
                                        {Array.from({ length: Math.ceil(totalUsers / pageSize) }).map((_, index) => (
                                            <Pagination.Item
                                                key={index}
                                                onClick={() => handlePageChange(index + 1)}
                                                active={index + 1 === currentPage}
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        ))}
                                    </Pagination>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Users;
