import React, { useState, useEffect } from 'react';
import { Alert, Container } from 'react-bootstrap';
import SectionFooter from './Homepage/SectionFooter';
import SectionHero from './Homepage/SectionHero';
import axios from 'axios';
import { useParams } from 'react-router-dom';


const DeleteAccountConfirm: React.FC = () => {
    // Extract the token from the URL using useParams
    const { token } = useParams<any>();
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        // Verify the token if it is present
        const verifyToken = async () => {
            if (token) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-delete-account-request`,
                        { token }, // Send the token to the server
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    );

                    setSuccess(response.data.message);
                } catch (error: any) {
                    console.error(error);
                    console.log(error);
                    if (error.response && error.response.data) {
                        setError(error.response.data.message);
                    }
                    else {
                        setError("Sorry, unable to confirm the deletion request. Make sure your link was not expired");
                    }
                } finally {
                    setIsLoading(false);
                }
            } else {
                setError('Token is missing.');
                setIsLoading(false);
            }
        };

        verifyToken();
    }, [token]);

    return (
        <Container fluid className="p-0 d-flex flex-column min-vh-100">
            <SectionHero />
            <div className="flex-grow-1 d-flex align-items-center justify-content-center py-5">
                <Container className="mt-5 bg-white rounded p-4" style={{ maxWidth: '500px' }}>
                    <div className="text-center mb-4">
                        <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px' }}>Confirm Account Deletion Request</span>
                    </div>
                    {/* <p className="text-center mb-4">We’re processing your account deletion request.</p> */}
                    {isLoading && <p className="text-center">Loading...</p>}
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <p className="text-center mt-4"><a href="/">Return to Home</a></p>
                </Container>
            </div>
            <SectionFooter />
        </Container>
    );
};

export default DeleteAccountConfirm;
