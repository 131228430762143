import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { API_GET_USERS, API_PUT_USERS } from '../../backend/apis';
import api from '../../backend/axios-config';
import { showError, showMessage } from '../../utils/error-handler';

interface User {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    onboarding_status: string;
    phone: string;
}

const UserDetail: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { userId } = useParams<{ userId: string }>();
    const [user, setUser] = useState<User>({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        onboarding_status: '',
        phone: ''
    } as any);
    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true);
                const response = await api.get(`${API_GET_USERS}/${userId}`);
                // if (!response.ok) {
                //     throw new Error('Failed to fetch user');
                // }
                const userData = response.data["data"];
                setLoading(false);
                setUser(userData);

            } catch (error: any) {
                setLoading(false);
                showError(error.message);
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, [userId]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) {
            // alert();
            return;
        }
        try {

            if (!user.first_name) {
                showError("First Name is required");
                return;
            }

            if (!user.last_name) {
                showError("Last Name is required");
                return;
            }

            if (!user.email) {
                showError("Email is required");
                return;
            }


            if (!user.phone) {
                showError("Phone is required");
                return;
            }

            setLoading(true);
            const response = await api.put(API_PUT_USERS + "/" + userId, user);
            console.log(JSON.stringify(response.data));
            showMessage(response.data.message);
            if (response.data.status === 200) {
                const user = response.data.data;
                console.log(JSON.stringify(user));
            }
        } catch (error: any) {
            setLoading(false);
            showError(error.message);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    if (user == null) {
        return (
            <Container className="mt-5">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Card>
                            <Card.Body>
                                <h1>User detail not found</h1>
                                <p>Sorry, we cannot find any related user details</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>);
    }
    return (
        <Container className="mt-5">
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Form onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body style={{ textAlign: "start" }}>
                                <h1 className="mb-4">User Detail</h1>
                                <Form.Group controlId="first_name" className='mb-2'>
                                    <label>First Name</label>
                                    <Form.Control type="text" name="first_name" placeholder="Enter first name" value={user.first_name} onChange={(e: any) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group controlId="last_name" className='mb-2'>
                                    <label>Last Name</label>
                                    <Form.Control type="text" name="last_name" placeholder="Enter last name" value={user.last_name} onChange={(e: any) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group controlId="email" className='mb-2'>
                                    <label>Email</label>
                                    <Form.Control type="email" name="email" placeholder="Enter email" value={user.email} disabled />
                                </Form.Group>
                                {/* <Form.Group controlId="role" className='mb-2'>
                                    <Form.Control type="text" name="role" placeholder="Enter role" value={user.role} onChange={(e: any) => handleChange(e)} />
                                </Form.Group> */}
                                <Form.Group controlId="phone" className='mb-2'>
                                    <label>Phone</label>
                                    <Form.Control type="text" name="phone" placeholder="Enter phone" value={user.phone} onChange={(e: any) => handleChange(e)} />
                                </Form.Group>

                                <Form.Group controlId="onboarding_status" className='mb-2'>
                                    <label>On Boarding Status</label>
                                    <Form.Control type="text" name="onboarding_status" placeholder="" value={user.onboarding_status} disabled />
                                </Form.Group>
                                {/* <Form.Group controlId="status" className='mb-2'>
                                    <label>Status</label>
                                    <Form.Select name="status" value={user.status} onChange={(e: any) => handleChange(e)} disabled>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                        <option value="pending">Pending</option>
                                    </Form.Select>
                                </Form.Group> */}
                            </Card.Body>
                            <Card.Footer className="d-grid">
                                <div className='d-flex justify-content-end'>
                                    <Link type="submit" className='btn btn-light mx-2' to={"/users"}>
                                        Back
                                    </Link>
                                    <Button variant="dark" type="submit">
                                        Update
                                    </Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default UserDetail;
