import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

// interface Step {
//   description: string;
// }
const enableActions = false;
const PreparationSteps: React.FC<{ dataList: string[] }> = ({ dataList }) => {
    const [showModal, setShowModal] = useState(false);
    const [steps, setSteps] = useState<string[]>([]);
    useEffect(() => {
        setSteps(dataList);
    }, [dataList]);
    const handleAddStep = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSave = (text: string) => {
        const lines = text.split('\n');
        const newSteps: string[] = lines.map(line => {
            //   return { description: line.trim() };
            return line.trim();
        });
        setSteps(newSteps);
        setShowModal(false);
    };

    const handleRemoveStep = (index: number) => {
        const updatedSteps = [...steps];
        updatedSteps.splice(index, 1);
        setSteps(updatedSteps);
    };

    return (
        <div>
            {enableActions && <Button onClick={handleAddStep} variant="dark" className='my-2 btn-sm'>Add Preparation Step</Button>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Description</th>
                        {enableActions && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {steps.length === 0 && <tr>
                        <td colSpan={2}>No preparation steps added</td>
                    </tr>}
                    {steps.map((step, index) => (
                        <tr key={index}>
                            <td>{step}</td>
                            {enableActions && <td>
                                <Button variant="danger" size="sm" onClick={() => handleRemoveStep(index)}>Remove</Button>
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Preparation Step</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea className="form-control" rows={5} id="stepTextArea"></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    <Button variant="primary" onClick={() => handleSave((document.getElementById('stepTextArea') as HTMLTextAreaElement).value)}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PreparationSteps;
