import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { showError, showMessage } from '../../utils/error-handler';
import { API_FORGOT_PASSWORD } from '../../backend/apis';
import api from '../../backend/axios-config';

const ForgotPassword: React.FC = () => {
    const [formObject, setFormObject] = useState({
        email: ''
    });
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if (loading) {
            return;
        }

        try {
            if (!formObject.email) {
                setError("Email is required");
                return;
            }

            setLoading(true);
            const response = await api.post(API_FORGOT_PASSWORD, formObject);
            showMessage(response.data.message);

            if (response.data.status === 200) {
                setMessage('Password reset link sent successfully.');
                setFormObject(prevState => ({ ...prevState, email: '' }));
            }
        } catch (error: any) {
            showError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="vh-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: "#003123" }}>
            <Container className="m-4 mt-5 bg-white rounded p-4" style={{ maxWidth: '400px' }}>
                <div className="text-center mb-4">
                    <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px', position: 'relative', top: '-10px' }}>Forgot Password</span>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={formObject.email}
                            onChange={(e) => setFormObject(prevState => ({ ...prevState, email: e.target.value }))}
                        />
                    </Form.Group>

                    {error && <div className="text-danger mb-3">{error}</div>}
                    {message && <div className="text-success mb-3">{message}</div>}

                    <Button variant="success" type="submit" className="w-100" disabled={loading}>
                        {loading ? 'Sending...' : 'Submit'}
                    </Button>

                    <div className="mt-3 text-center">
                        <Link to="/login">Return to Login</Link>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default ForgotPassword;
