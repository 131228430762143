import { Col, Container, Row } from "react-bootstrap";

export default function SectionFooter() {
    return (<footer className="text-center text-white py-4">
        <Container>
            <Row>
                <Col xs={12} md={6}>
                    <p className="mb-0">2024 &copy; Satiate LLC. All rights reserved.</p>
                </Col>
                <Col xs={12} md={6}>
                    <a href="/" className="text-white text-decoration-none">Home</a>
                    {" | "}
                    <a href="/about" className="text-white text-decoration-none">About</a>
                    {" | "}
                    <a href="/login" className="text-white text-decoration-none">Login</a>
                    {" | "}
                    <a href="/privacy" className="text-white text-decoration-none">Privacy</a>
                    {" | "}
                    <a href="/terms" className="text-white text-decoration-none">Terms and Conditions</a>
                </Col>
            </Row>
        </Container>
    </footer>)
}