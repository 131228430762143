import axios from 'axios';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from './tokenStorage';
import { API_REFRESH_TOKEN } from './apis';
// import { API_URL } from '../config/config';
// import { getAccessToken } from './tokenStorage';

// console.log('REACT_JS_API_URL:', process.env.REACT_APP_API_URL);

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});


// Function to refresh token using refresh token
const refreshAccessToken = async () => {
    try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            console.log('No refresh token available');
            removeTokens(); // Clear tokens and handle logout
            return null;
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}${API_REFRESH_TOKEN}`, { refreshToken });
        const accessToken = response.data.data;

        if (accessToken) {
            setAccessToken(accessToken); // Store the new access token
            return accessToken;
        } else {
            console.log('Failed to refresh token');
            removeTokens(); // Clear tokens and handle logout
            return null;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        removeTokens(); // Clear tokens and handle logout
        return null;
    }
};


// Request interceptor to modify outgoing requests
instance.interceptors.request.use(
    async (config) => {
        try {
            const token = await getAccessToken();
            // console.log("----------------------------");
            // console.log(token);
            // console.log("----------------------------");
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            // console.log(config);
            return config;
        } catch (error) {
            console.error('Error in request interceptor:', error);
            return Promise.reject(error);
        }
    },
    (error) => {
        console.error('Error in request interceptor:', error);
        return Promise.reject(error);
    });


// Request interceptor to modify outgoing requests
instance.interceptors.request.use(
    async (config) => {
        try {
            const token = await getAccessToken();
            // console.log("----------------------------");
            // console.log(token);
            // console.log("----------------------------");
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            // console.log(config);
            return config;
        } catch (error) {
            console.error('Error in request interceptor:', error);
            return Promise.reject(error);
        }
    },
    (error) => {
        console.error('Error in request interceptor:', error);
        return Promise.reject(error);
    });

// Response interceptor to handle responses
// Response interceptor to handle errors, including token expiration
instance.interceptors.response.use(
    (response) => {
        // Handle successful responses
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            console.log('Token expired. Attempting to refresh token...');
            const newToken = await refreshAccessToken();
            if (newToken) {
                // Retry the original request with the new token
                error.config.headers.Authorization = `Bearer ${newToken}`;
                return axios.request(error.config);
            } else {
                // Handle failed token refresh
                console.log('Failed to refresh token');
                removeTokens(); // Clear tokens and handle logout
                // Redirect to login or handle unauthorized access
                return Promise.reject('Failed to refresh token');
            }
        }

        // Handle other response errors
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        }
        console.error('Error in response interceptor:', JSON.stringify(error));
        return Promise.reject(error);
    }
);
export default instance;
