import React from 'react';
import { Container } from 'react-bootstrap';
import SectionHero from './Homepage/SectionHero';
import SectionFooter from './Homepage/SectionFooter';

const TermsAndConditions: React.FC = () => {
    return (
        <Container fluid className="p-0">
            <SectionHero />
            <Container className="py-5">
                <h1 className="text-center mb-4">Terms and Conditions</h1>
                <p>
                    Welcome to NutreeLover.com! These terms and conditions outline the rules and regulations for the use of our website and mobile application.
                </p>
                <h3>1. Acceptance of Terms</h3>
                <p>
                    By accessing this website and/or using our mobile application, we assume you accept these terms and conditions. Do not continue to use NutreeLover.com if you do not agree to all of the terms and conditions stated on this page.
                </p>
                <h3>2. Use License</h3>
                <p>
                    Permission is granted to temporarily download one copy of the materials (information or software) on NutreeLover.com for personal, non-commercial transitory viewing only.
                </p>
                <h3>3. User Content</h3>
                <p>
                    By submitting content to NutreeLover.com, you grant us the right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world.
                </p>
                <h3>4. Disclaimer</h3>
                <p>
                    The materials on NutreeLover.com are provided on an 'as is' basis. We make no warranties, expressed or implied.
                </p>
                <h3>5. Limitations</h3>
                <p>
                    In no event shall NutreeLover.com or its suppliers be liable for any damages arising out of the use or inability to use our website or mobile application.
                </p>
                <h3>6. Governing Law</h3>
                <p>
                    These terms and conditions are governed by and construed in accordance with the laws of the United States of America, and you irrevocably submit to the exclusive jurisdiction of the courts in that jurisdiction.
                </p>
            </Container>
            <SectionFooter />
        </Container>
    );
}

export default TermsAndConditions;
