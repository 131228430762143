// Define the key for storing the token in localStorage
const KEY = 'auth_user';

// Function to store the token in localStorage
export const saveUser = (token) => {
  try {
    localStorage.setItem(KEY, JSON.stringify(token));
  } catch (error) {
    console.error('Error storing user:', error);
    // Handle error (e.g., display error message)
  }
};

// Function to retrieve the token from localStorage
export const getUser = () => {
  try {
    const token = localStorage.getItem(KEY);
    if (token !== null) {
      return JSON.parse(token);
    }
    return {};
  } catch (error) {
    console.error('Error retrieving user:', error);
    // Handle error (e.g., display error message)
    return {};
  }
};

// Function to remove the token from localStorage
export const removeUser = () => {
  try {
    localStorage.removeItem(KEY);
  } catch (error) {
    console.error('Error removing user:', error);
    // Handle error (e.g., display error message)
  }
};
