import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import IngredientList from './IngredientList';
import PreparationSteps from './PreparationStep';
import NutritionValues from './NutritionValues';
import { showError } from '../../utils/error-handler';
import { API_GET_RECIPES } from '../../backend/apis';
import api from '../../backend/axios-config';
import { getRecipeImageUrl } from '.';

const enableActions = false;
interface Recipe {
    id: string;
    title: string;
    description: string;
    ingredients: any;
    steps: any;
    nutritionValues: string;
    feature_tags: string[];
    promotional_tags: string[];
    image_url: string;
    cuisine: string;
    preparationTime: string;
    cooking_time: string;
    cookingMethod: string;
    videoURL: string;
}

const RecipeDetail: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { recipeId } = useParams<{ recipeId: string }>();
    const [recipe, setRecipe] = useState<Recipe>({
        id: '',
        title: '',
        description: '',
        ingredients: [],
        steps: [],
        nutritionValues: '',
        feature_tags: [],
        promotional_tags: [],
        image_url: '',
        cuisine: '',
        preparationTime: '',
        cooking_time: '',
        cookingMethod: '',
        videoURL: ''
    });
    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                setLoading(true);
                const response = await api.get(`${API_GET_RECIPES}/${recipeId}`);
                // if (!response.ok) {
                //     throw new Error('Failed to fetch user');
                // }
                const recipeData = response.data["data"];
                setLoading(false);
                setRecipe(recipeData);

            } catch (error: any) {
                setLoading(false);
                showError(error.message);
                console.error('Error fetching user:', error);
            }
        };

        fetchRecipe();
    }, [recipeId]);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Implement logic to submit the form data
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setRecipe({ ...recipe, [e.target.name]: e.target.value });
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            // Perform image upload logic
            console.log("Image uploaded:", file);
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1 className="mb-4">Recipe Detail</h1>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    placeholder="Enter title"
                                    value={recipe.title}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="description"
                                    placeholder="Enter description"
                                    value={recipe.description}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        {/* <Row className="mb-3">
                            <Form.Group as={Col} controlId="ingredients">
                                <Form.Label>Ingredients</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="ingredients"
                                    placeholder="Enter ingredients"
                                    value={recipe.ingredients}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row> */}
                        {recipe.ingredients && <IngredientList dataList={recipe.ingredients} />}
                        {recipe.ingredients && <PreparationSteps dataList={recipe.steps} />}
                        <NutritionValues />
                        {/* <Row className="mb-3">
                            <Form.Group as={Col} controlId="steps">
                                <Form.Label>Steps</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="steps"
                                    placeholder="Enter steps"
                                    value={recipe.steps}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row> */}
                        {/* <Row className="mb-3">
                            <Form.Group as={Col} controlId="nutritionValues">
                                <Form.Label>Nutrition Values</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="nutritionValues"
                                    placeholder="Enter nutrition values"
                                    value={recipe.nutritionValues}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row> */}
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="tags">
                                <Form.Label>Relevent Tags</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="tags"
                                    placeholder="Enter comma seperated tags"
                                    value={recipe.feature_tags}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="tags">
                                <Form.Label>Tags (for Marketing)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="feature_tags"
                                    placeholder="Enter comma seperated tags"
                                    value={recipe.feature_tags}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="image">
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type={enableActions ? "file" : "label"}
                                    name="image"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{ height: '350px', border: '2px dashed #ccc', background: `url(${getRecipeImageUrl(recipe.image_url)}) no-repeat`, backgroundSize: "contain", backgroundPositionX: 400 }}
                                />
                            </Form.Group>
                        </Row>
                        {/* <Row className="mb-3">
                            <Form.Group as={Col} controlId="image">
                                <Form.Label>Image</Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <Form.Control
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                    />
                                    <div
                                        style={{
                                            height: '350px',
                                            border: '2px dashed #ccc',
                                            background: `url(${recipe.image_url}) no-repeat`,
                                            backgroundSize: 'cover',
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            src={recipe.image_url}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                opacity: 0, // Hide the image but keep its dimensions for the background
                                            }}
                                            alt={recipe.image_url}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                            }}
                                        >
                                            <span style={{ color: '#000', fontSize: '16px' }}>Click to upload image</span>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Row> */}

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="cuisine">
                                <Form.Label>Cuisine</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cuisine"
                                    placeholder="Enter cuisine"
                                    value={recipe.cuisine}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="preparationTime">
                                <Form.Label>Preparation Time</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="preparationTime"
                                    placeholder="Enter preparation time"
                                    value={recipe.preparationTime}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="cooking_time">
                                <Form.Label>Cooking Time (in seconds)</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cooking_time"
                                    placeholder="Enter cooking time "
                                    value={recipe.cooking_time}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="preparationTime">
                                <Form.Label>Video URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="videoURL"
                                    placeholder="Enter video URL"
                                    value={recipe.videoURL}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3 justify-content-end">
                            <Col>
                                <div className="d-flex gap-2 justify-content-end">
                                    <Link className="btn btn-light me-2" to={"/recipes"}>
                                        Back
                                    </Link>
                                    {/* <Button variant="dark" type="submit">
                                        Update
                                    </Button> */}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default RecipeDetail;
