import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, Table, Breadcrumb, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API_GET_RECIPES } from '../../backend/apis';
import api from '../../backend/axios-config';
import debounce from 'lodash/debounce';
import { showError } from '../../utils/error-handler';


export const getRecipeImageUrl=(imageUrl:any)=>{
    if(imageUrl.startsWith("http")){
        return imageUrl;
    }
    return "https://api.nutreelover.com/images/recipes/"+imageUrl;
}

interface Recipe {
    id: number;
    title: string;
    description: string;
    image_url: string;
    preparation_time: number;
    cooking_time: number;
    meal_types: string[];
    ingredients: string[];
    steps: string[];
}

interface ApiResponse {
    recipes: Recipe[];
    pagination: {
        currentPage: number;
        pageSize: number;
        totalPages: number;
        totalCount: number;
    };
}

const Recipes: React.FC = () => {
    const [recipesList, setRecipesList] = useState<Recipe[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const recipesPerPage = 10; // Number of recipes per page
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRecipesList();
    }, [currentPage, searchTerm]);

    const fetchRecipesList = useCallback(async () => {
        try {
            setLoading(true);

            const response = await api.get<ApiResponse>(API_GET_RECIPES, {
                params: {
                    keyword: searchTerm,
                    page: currentPage,
                    pageSize: recipesPerPage
                }
            }) as any;

            setLoading(false);
            const { recipes } = response.data.data;
            setRecipesList(recipes);
        } catch (error: any) {
            setLoading(false);
            console.error('Error fetching recipes list:', error);
            showError(error.message);
        }
    }, [searchTerm, currentPage]);

    // Debounced search term change handler
    const handleSearchChange = useCallback(
        debounce((searchValue: string) => {
            setSearchTerm(searchValue);
        }, 300),
        []
    );

    // Change page to the next page
    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    // Change page to the previous page
    const handlePrevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    return (
        <Container fluid className="mt-3">
            <Row className="mt-3">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to="/recipes">Recipes</Link></Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header as="h5">Recipes List</Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by title"
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                />
                            </Form.Group>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Preview</th>
                                        <th style={{ textAlign: "left" }}>Title</th>
                                        <th>Preparation Time</th>
                                        <th>Cooking Time</th>
                                        <th>Meal for</th>
                                        <th>Ingredients</th>
                                        <th>Steps</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recipesList.length === 0 ? (
                                        <tr><td colSpan={9}>No Record Found</td></tr>
                                    ) : (
                                        recipesList.map((recipe, index) => (
                                            <tr key={"recipe-" + recipe.id}>
                                                <td>{index + 1}</td>
                                                <td style={{ textAlign: "left", width: 75 }}>
                                                    <img src={getRecipeImageUrl(recipe.image_url)} style={{ width: 75, height: 75, boxShadow: "0px 0px 1px 1px #CCCCCC" }} alt={recipe.title} />
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    <h5>{recipe.title}</h5>
                                                    <p>{recipe.description.substring(0, 100) + "..."}</p>
                                                </td>
                                                <td>{(recipe.preparation_time / 60) + " minutes"}</td>
                                                <td>{(recipe.cooking_time / 60) + " minutes"}</td>
                                                <td>{recipe.meal_types && recipe.meal_types.map((mType) => (
                                                    <span key={mType} style={{ border: "1px dashed #CCCCCC", margin: 5, padding: 5 }}>{mType}</span>
                                                ))}</td>
                                                <td>{recipe.ingredients.length}</td>
                                                <td>{recipe.steps.length}</td>
                                                <td>
                                                    <Link className="btn btn-success" to={"/recipes/" + recipe.id}>View Details</Link>{' '}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-between mt-3">
                                <Button variant="outline-secondary" onClick={handlePrevPage} disabled={currentPage === 1}>
                                    Previous
                                </Button>
                                <Button variant="outline-secondary" onClick={handleNextPage}>
                                    Next
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Recipes;
