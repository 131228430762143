import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { showError } from '../../utils/error-handler';
import { API_LOGIN } from '../../backend/apis';
import api from '../../backend/axios-config';
import { storeToken } from '../../backend/tokenStorage';
import SectionHero from './Homepage/SectionHero';
import SectionFooter from './Homepage/SectionFooter';

const Login: React.FC = () => {
    const [formObject, setFormObject] = useState({
        email: '',
        password: '',
        rememberMe: true
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (name: any, value: any) => {
        setFormObject({ ...formObject, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        try {
            if (!formObject.email) {
                showError("Email is required");
                return;
            }

            if (!formObject.password) {
                showError("Password is required");
                return;
            }
            setLoading(true);
            const response = await api.post(API_LOGIN, formObject);
            if (response.data.status === 200) {
                const user = response.data.data;
                storeToken(user["tokens"]);
                // await storeToken(user.token);
                window.location.href = window.location.href;
            }
        } catch (error: any) {
            setLoading(false);
            showError(error.message);
        }
    };

    return (
        <Container fluid className="p-0 d-flex flex-column min-vh-100">
            <SectionHero />
            <div className="flex-grow-1 d-flex align-items-center justify-content-center py-5">
                <Container className="mt-5 bg-white rounded p-4" style={{ maxWidth: '400px' }}>
                    <div className="text-center mb-4">
                        <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '24px' }}>NutreeLover Login</span>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={formObject.email}
                                onChange={(e: any) => handleChange('email', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={formObject.password}
                                onChange={(e) => handleChange('password', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="rememberMe">
                            <Form.Check
                                type="checkbox"
                                label="Remember Me"
                                checked={formObject.rememberMe}
                                onChange={(e) => handleChange('rememberMe', e.target.checked)}
                            />
                        </Form.Group>

                        {error && <div className="text-danger mb-3">{error}</div>}

                        <Button variant="success" type="submit" className="w-100">
                            {loading ? 'Loading...' : 'Login'}
                        </Button>

                        <Link to="/forgot-password" className="mt-3 d-block text-end" style={{ textDecoration: 'none', color: 'green' }}>Forgot Password?</Link>
                    </Form>
                </Container>
            </div>
            <SectionFooter />
        </Container>
    );
}

export default Login;
